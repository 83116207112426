import React from "react";
import "katex/dist/katex.min.css";
import BaseLayout from "./BaseLayout";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import PageTitle from "./PageTitle";
import { getMetaTags, getFullPageUrl } from "../Utils";
import { DiscussionEmbed } from "disqus-react";
require("prismjs/themes/prism.css");
require("katex/dist/katex.min.css");

const BlogPost = ({ data: { mdx, site } }) => {
  let updatedDateElem;
  if (mdx.frontmatter.machineReadableUpdateDate === null) {
    updatedDateElem = null;
  } else {
    updatedDateElem = (
      <span>
        {" "}
        (updated{" "}
        <time dateTime={mdx.frontmatter.machineReadableUpdateDate}>
          {mdx.frontmatter.displayedUpdateDate}
        </time>
        )
      </span>
    );
  }
  const title = `${mdx.frontmatter.title} | ${site.siteMetadata.title}`;
  const metaTags = getMetaTags({
    title,
    siteUrl: site.siteMetadata.siteUrl,
    slug: mdx.fields.slug,
    summary: mdx.frontmatter.summary,
  });

  const disqusShortname = "huy-nguyen";
  const disqusConfig = {
    url: getFullPageUrl(site.siteMetadata.siteUrl, mdx.fields.slug),
    identifier: mdx.fields.slug,
    title,
  };
  let comments;
  if (process.env.NODE_ENV === "production") {
    comments = (
      <DiscussionEmbed shortname={disqusShortname} config={disqusConfig} />
    );
  } else {
    comments = null;
  }
  return (
    <BaseLayout>
      {metaTags}
      <PageTitle>{mdx.frontmatter.title}</PageTitle>
      <div className="d-block my-2">
        <time dateTime={mdx.frontmatter.machineReadablePublicationDate}>
          {mdx.frontmatter.displayedPublicationDate}
        </time>
        {updatedDateElem}
      </div>
      <MDXRenderer>{mdx.body}</MDXRenderer>
      {comments}
    </BaseLayout>
  );
};
export const pageQuery = graphql`
  query DynamicPost($id: String) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    mdx(id: { eq: $id }) {
      id
      body
      fields {
        slug
      }
      frontmatter {
        title
        displayedPublicationDate: date(formatString: "MMMM DD, YYYY")
        machineReadablePublicationDate: date(formatString: "YYYY-MM-DD")
        displayedUpdateDate: updated(formatString: "MMMM DD, YYYY")
        machineReadableUpdateDate: updated(formatString: "YYYY-MM-DD")
      }
    }
  }
`;

export default BlogPost;
